<template>
  <!-- <div style="display: none;">
    <index-chart v-if="indexStyle==1"></index-chart>
    <index-bdc v-if="indexStyle==2"></index-bdc>
    <index-task v-if="indexStyle==3"></index-task>
    <div style="width: 100%;text-align: right;margin-top: 20px">
      请选择首页样式：
      <a-radio-group v-model="indexStyle">
        <a-radio :value="1">统计图表</a-radio>
        <a-radio :value="2">统计图表2</a-radio>
        <a-radio :value="3">任务表格</a-radio>
      </a-radio-group>
    </div>
  </div> -->
  <div>
    <!-- 登陆成功后弹框 -->
    <a-modal v-model="userModalVisible" title="填写个人信息" @ok="handleOk" width="36%">
     <login-user-modal ref="userFrom"  @closeUserModal="closeUserModal" :userInfo="userInfo"></login-user-modal>
    </a-modal>
  </div>
</template>

<script>
import IndexChart from './IndexChart'
import IndexTask from './IndexTask'
import IndexBdc from './IndexBdc'
import LoginUserModal from './loginUserModal'

export default {
  name: 'Analysis',
  components: {
    IndexChart,
    IndexTask,
    IndexBdc,
    LoginUserModal
  },
  data() {
    return {
      userModalVisible: true,
      userInfo:{},
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: '/sys/user/list',
        delete: '/sys/user/delete',
        deleteBatch: '/sys/user/deleteBatch',
        exportXlsUrl: '/sys/user/exportXls',
        importExcelUrl: 'sys/user/importExcel',
      },
    }
  },
  created() {
    const {fromPage,userInfo} = this.$route.query;
    
    if(fromPage === "login") {
      // this.userModalVisible = true
      this.userInfo = JSON.parse(decodeURIComponent(userInfo))
    }

  },
  methods: {
    closeUserModal(){
      this.userModalVisible = false
    },
    handleOk() {
       this.$refs.userFrom.saveForm();
    }
  }
}
</script>
